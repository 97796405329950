import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider as ReactUIWalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets"; // Removed SolletWalletAdapter and SolletExtensionWalletAdapter
import { FC, ReactNode, useCallback, useMemo } from "react";
import { AutoConnectProvider, useAutoConnect } from "./AutoConnectProvider";
import { NetworkConfigurationProvider, useNetworkConfiguration } from "./NetworkConfigurationProvider";
import { toast } from "react-toastify";
import React from "react";

const WalletContextProvider = ({ children }) => {
  const { autoConnect } = useAutoConnect();
  const { networkConfiguration } = useNetworkConfiguration();
  const network = networkConfiguration;

  const endpoint = "https://solana-mainnet.gateway.tatum.io/";

  // Create a custom fetch function to add headers
  // Custom fetch function with headers
  const customFetch = async (url, options) => {
    // Add custom headers
    const customHeaders = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-Api-Key": "t-661eea76cdbf05001ca76c67-bcf9b83731834c5995fee266", // Your API Key
    };

    // Merge the custom headers with any other existing headers
    const mergedOptions = {
      ...options,
      headers: {
        ...customHeaders,
        ...(options?.headers || {}),
      },
    };

    // Perform the fetch request with the merged options
    const response = await fetch(url, mergedOptions);

    // Check for any errors in the response
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    return response;
  };

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(), // Removed Sollet and SolletExtensionWalletAdapter
    ],
    [network],
  );

  const onError = useCallback((error) => {
    toast(error.message ? `${error.name}: ${error.message}` : error.name);
    console.error(error);
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint} config={{ fetch: customFetch }}>
      <WalletProvider
        wallets={wallets}
        onError={onError}
        autoConnect={autoConnect}
      >
        <ReactUIWalletModalProvider>{children}</ReactUIWalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export const ContextProvider = ({ children }) => {
  return (
    <NetworkConfigurationProvider>
      <AutoConnectProvider>
        <WalletContextProvider>{children}</WalletContextProvider>
      </AutoConnectProvider>
    </NetworkConfigurationProvider>
  );
};
