import { Provider } from "react-redux";
import store from "./store";
import Routes from "./routes";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "./utils/wagmi.tsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const App = () => {
  const queryClient = new QueryClient();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Run animation only once
    });
  }, []);

  return (
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  );
};

export default App;
