// import _404 from "/components/common/_404";
import _404 from "../components/common/_404";
import Unauthorized from "../components/common/Unauthorized";
import { Coins, Coins_Detail, Create_Nft, Create_Sol_Nft, Dashboard, ForgotPassword, Login, Profile, Signup } from "./routeImports";
import routesConstants from "./routesConstants";

const routesConfig = {
  common: [
    {
      path: routesConstants.UNAUTHORIZED,
      component: Unauthorized,
    },
    { path: routesConstants._404, component: _404 },
  ],
  private: [
    {
      path: routesConstants.HOME,
      component: Dashboard,
    },
    {
      path: routesConstants.CREATE_NFT,
      component: Create_Nft,
    },
    {
      path: routesConstants.CREATE_SOl_NFT,
      component: Create_Sol_Nft,
    },
    {
      path: routesConstants.COIN_DETAIL,
      component: Coins_Detail,
    },
    {
      path: routesConstants.PROFILE,
      component: Profile,
    },
    {
      path: routesConstants.NFT_LISTING,
      component: Coins,
    },
  ],
  public: [
    { path: routesConstants?.LOGIN, component: Login },
    {
      path: routesConstants.HOME,
      component: Dashboard,
    },
    { path: routesConstants?.REGISTER, component: Signup },
    {
      path: routesConstants?.FORGOT_PASSWORD,
      component: ForgotPassword,
    },
  ],
};

export default routesConfig;
