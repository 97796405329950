// Use lazy loaded pages
import { lazy } from "react";
/* webpackChunkName: "Dashboard" */
export const Dashboard = lazy(() => import("../modules/dashboard/pages"));
export const Coins = lazy(() => import("../modules/coins/pages"));
export const Coins_Detail = lazy(() => import("../modules/coins-details/pages"));
export const Create_Nft = lazy(() => import("../modules/create-nft/pages"));
export const Create_Sol_Nft = lazy(() => import("../modules/create-sol-nft/pages"));
export const Admin = lazy(() => import("../modules/admins/pages"));
// const Profile = lazy(() => import("../components/Profile"));
// const Customer = lazy(() => import("../components/Customer"));
// const Home = lazy(() => import("../components/Home"));
export const Profile = lazy(() => import("../modules/profile/pages"));
export const Login = lazy(() => import("../modules/login/pages"));
export const Signup = lazy(() => import("../modules/register/pages"));
export const ForgotPassword = lazy(() =>
  import("../modules/login/components/ForgotPassword")
);
