import * as React from 'react';
import { useChainId, useConnect } from 'wagmi';

// You can import your images like this
// import MetaMaskLogo from '/public/images/metamasklogo.png'; // Update with the actual path to your MetaMask logo image
// import WalletConnectLogo from '/public/images/walletconnect.png'; // Update with the actual path to your WalletConnect logo image

export function Connect() {
    const chainId = useChainId();
    const { connectors, connect } = useConnect();

    return (
        <div className="buttons d-flex justify-content-center">
            {connectors.map((connector) => (
                <ConnectorButton
                    key={connector.uid}
                    connector={connector}
                    onClick={() => connect({ connector, chainId })}
                />
            ))}
        </div>
    );
}

function ConnectorButton({ connector, onClick }) {
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const provider = await connector.getProvider();
            setReady(!!provider);
        })();
    }, [connector, setReady]);

    // Determine the image to use based on the connector name
    const getConnectorImage = (name) => {
        switch (name) {
            case 'MetaMask':
                return "";
            case 'WalletConnect':
                return "";
            default:
                return ''; // or a default image if you have one
        }
    };

    return (
        <button
            // className="button"
            disabled={!ready}
            onClick={onClick}
            type="button"
        >
            <img
                src={getConnectorImage(connector.name)}
                alt={`${connector.name} logo`}
                style={{ height: '60px' }} // Adjust the size as needed
            />
            {connector.name}
        </button>
    );
}
