import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import { useState } from "react";

const Layout = () => {
  const { isAuth } = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation(); // Get the current route

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Check if the current route includes "/dashboard"
  const isDashboardRoute = location.pathname.includes("/dashboard");

  return (
    <div className="wrapper d-flex align-items-stretch">
      <div className="app w-100">
        {isDashboardRoute && <Header toggleSidebar={toggleSidebar} />}
        <div className="content">
          {isAuth ? (
            <>
              <Header toggleSidebar={toggleSidebar} />
              <Outlet />
            </>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
