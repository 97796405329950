import conf from "../conf/conf"
// import conf from "@/conf/conf";
import cookies from "js-cookie";

const cookieConfig = {
  path: conf.cookiePath,
  domain: conf.cookieDomain,
  expires: +conf.cookieExpires,
};

export const cookieKeys = {
  TOKEN: "token",
  USER: "user",
};

class Cookies {
  static isValidJSON(value) {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  static get(key) {
    const value = cookies.get(key);
    if (value && this.isValidJSON(value)) {
      return JSON.parse(value);
    }
    return value; // return as is if not valid JSON
  }

  static set(key, value, config = cookieConfig) {
    return cookies.set(key, JSON.stringify(value), config);
  }

  static remove(key, config = cookieConfig) {
    cookies.remove(key, config);
  }

  static clear() {
    Object.values(cookieKeys).forEach((key) => {
      Cookies.remove(key, cookieConfig); // ensure config is passed here
    });
  }
}

export default Cookies;
