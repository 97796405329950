// import { LOGIN } from "@/services/url";
import { LOGIN } from "../../../services/url";
import Cookies from "../../../services/cookies";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosCoinex } from "../../../services/api";

const token = Cookies.get("token");
const userData = Cookies.get("user");
const loginState = {
  isAuth: !!token,
  user: userData ? userData : null,
  loading: false,
  token: token ? token : "",
  error: null,
};

export const getLogin = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosCoinex.post(LOGIN, payload);
      toast.success(response?.data?.message);
      Cookies.set("user", response?.data?.user);
      Cookies.set("token", response?.data?.token);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.statusCode);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: loginState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLogin.pending, (state) => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(getLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    });
    builder.addCase(getLogin.rejected, (state) => {
      state.loading = false;
      state.isAuth = false;
    });
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
