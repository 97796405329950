import ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import "@/assets/scss/style.scss";
import "../src/assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import '@solana/wallet-adapter-react-ui/styles.css';
import { ToastContainer } from "react-toastify";
import { ContextProvider } from "./contexts/ContextProvider";

ReactDOMClient.createRoot(document.querySelector("#root")).render(
  <BrowserRouter>
    <ToastContainer />
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
);
