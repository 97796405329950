const routesConstants = {
  LOGIN: "login",
  REGISTER: "/signup",
  HOME: "/dashboard",
  LANDING: "/landing",
  NFT_LISTING: "/coins",
  PROFILE: "/profile",
  COIN_DETAIL: "/coin-detail/:id",
  CREATE_NFT: "/add-coins",
  CREATE_SOl_NFT: "/add-sol-coins",
  ADMINS: "/admins",
  ENGINEERS: "/engineers",
  SKILLS: "/skills",
  COMPANIES: "/companies",
  SERVICES: "/services",
  DELIVERY_NOTES: "/delivery-notes",
  TEMPLATES: "/templates",
  PROJECTS: "/projects",
  JOBS: "/jobs",
  FORGOT_PASSWORD: "forgot-password",
  UNAUTHORIZED: "unauthorized",
  _404: "*",
  FAQ: "/faq",
  ABOUT: "/about",
  SUPPORT: "/support",
  PRIVACY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-condition",
};
export default routesConstants;
