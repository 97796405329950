import { http, createConfig } from 'wagmi';
import { arbitrum, arbitrumGoerli, avalanche, avalancheFuji, base, bsc, bscTestnet, fantom, fantomTestnet, foundry, goerli, mainnet, optimism, optimismGoerli, polygon, polygonMumbai, sepolia, tron } from 'wagmi/chains';
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors';

const projectId = '3fbb6bba6f1de962d911bb5b5c9dba88';

export const config = createConfig({
    chains: [arbitrum,
        arbitrumGoerli,
        // avalanche,
        // avalancheFuji,
        bsc,
        bscTestnet,
        fantom,
        fantomTestnet,
        foundry,
        goerli,
        mainnet,
        optimism,
        optimismGoerli,
        polygon,
        polygonMumbai,
        sepolia,],
    connectors: [walletConnect({ projectId })],
    transports: {
        [arbitrum.id]: http(),
        [arbitrumGoerli.id]: http(),
        // [avalanche.id]: http(),
        // [avalancheFuji.id]: http(),
        [bsc.id]: http(),
        [bscTestnet.id]: http(),
        [fantom.id]: http(),
        [fantomTestnet.id]: http(),
        [foundry.id]: http(),
        [goerli.id]: http(),
        [mainnet.id]: http(),
        [optimism.id]: http(),
        [optimismGoerli.id]: http(),
        [polygon.id]: http(),
        [polygonMumbai.id]: http(),
        [sepolia.id]: http(),
    },
});
