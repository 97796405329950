import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { recoverMessageAddress } from 'viem';
import { useAccount, useDisconnect, useEnsAvatar, useEnsName, useSignMessage } from 'wagmi';

export function Account() {
    // const { disconnect: disconnectSol } = useWallet()
    const { address, connector } = useAccount();
    const { data: signMessageData, error, isLoading, signMessage, variables } = useSignMessage()
    const { disconnect } = useDisconnect();
    const { data: ensName } = useEnsName({ address });
    const { data: ensAvatar } = useEnsAvatar({ name: ensName });

    const formattedAddress = formatAddress(address);

    useEffect(() => {
        (async () => {
            if (variables?.message && signMessageData) {
                const recoveredAddress = await recoverMessageAddress({
                    message: variables?.message,
                    signature: signMessageData,
                })


                // setRecoveredAddress(recoveredAddress)
            }
        })()
    }, [signMessageData, variables?.message])

    // useEffect(() => {
    //     disconnectSol()
    // }, [])



    return (
        <div className="row">
            <div className="inline">
                {ensAvatar ? (
                    <img alt="ENS Avatar" className="avatar" src={ensAvatar} />
                ) : (
                    <div className="avatar" />
                )}
                <div className="stack">
                    {address && (
                        <div className="text">
                            {ensName ? `${ensName} (${formattedAddress})` : formattedAddress}
                        </div>
                    )}
                    <div className="subtext">
                        Connected to {connector?.name} Connector
                    </div>
                </div>
            </div>
            <button className="button" onClick={() => disconnect()} type="button">
                Disconnect
            </button>
        </div>
    );
}

function formatAddress(address) {
    if (!address) return null;
    return `${address.slice(0, 6)}…${address.slice(38, 42)}`;
}
