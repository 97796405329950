import { logout } from "../modules/login/slice/loginSlice";
import Cookies from "../services/cookies";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAccount, useChainId, useConnect, useDisconnect } from "wagmi";
import CommonModal from "../components/common/modals/CommonModal";
import { useEffect, useRef, useState } from "react";
import { ConnectWallet } from "../components/wallet/ConnectWallet";
import { toast } from "react-toastify";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import copy from "copy-to-clipboard";

function formatAddress(address) {
  if (!address) return null;
  return `${address.slice(0, 6)}…${address.slice(38, 42)}`;
}

const Header = (prop) => {
  const chainId = useChainId();
  const { connectors, connect } = useConnect();
  const navigate = useNavigate();
  const { user, isAuth } = useSelector((state) => state.login);
  const [showMenu, setShowMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const { isConnected, chain } = useAccount();
  const { address, connector } = useAccount();
  const formattedAddress = formatAddress(address);
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();
  const menuRef = useRef(null); // Reference for the mobile menu
  const hamburgerRef = useRef(null); // Reference for the hamburger button

  //handle get logout
  const handleLogOut = () => {
    Cookies.clear();
    dispatch(logout());
    navigate("/login");
  };

  const handleConnect = () => {
    const metaMaskConnector = connectors.find((connector) => connector.name === "MetaMask");

    if (metaMaskConnector) {
      // Check if MetaMask is installed by checking for the provider
      metaMaskConnector.getProvider().then((provider) => {
        if (provider) {
          // MetaMask is installed, so we can attempt to connect
          connect({ connector: metaMaskConnector });
        }
      });
    } else {
      // MetaMask is not installed, show a message
      toast.error("Please install MetaMask in your chrome preferences");
    }
  };

  // Close menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both the menu and the hamburger button
      if (menuRef.current && !menuRef.current.contains(event.target) &&
        hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
        setShowMenu(false); // Close the menu if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, hamburgerRef]);

  // Toggle menu on hamburger click
  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle menu visibility
  };

  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link to="/dashboard" className="nav-link"><img src="/images/toro-logo.png" height="50px" width="50px" /></Link>
                <button
                  ref={hamburgerRef} // Attach the ref to the hamburger button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleMenu}
                >
                  {showMenu ? 'X' : <i className="fa fa-bars"></i>}
                </button>
                <div
                  className={`collapse navbar-collapse ${showMenu ? "show-menu" : ""}`}
                  id="navbarSupportedContent"
                  ref={menuRef} // Attach the ref to the menu
                >
                  <ul className="navbar-nav mr-auto w-100 justify-content-end">
                    {/* without login menu start */}
                    {!isAuth &&
                      <>
                        <li className="nav-item">
                          <Link to="/login" className="nav-link">Login</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/signup" className="nav-link">Register</Link>
                        </li>
                        {/* <li className="nav-item">
                          <a href={`${process.env.REACT_APP_SOL_URL}/liquidity/pools`} target="_blank" className="nav-link">
                            Liquidity
                          </a>
                        </li> */}
                      </>
                    }
                    {/* without login menu end  */}
                    {isAuth &&
                      <>
                        <li className="nav-item">
                          <Link to="/add-coins" className="nav-link">Create coin</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/coins" className="nav-link">Coins</Link>
                        </li>
                        <li className="nav-item">
                          <a href={`${process.env.REACT_APP_SOL_URL}/liquidity/pools`} target="_blank" className="nav-link">
                            Liquidity
                          </a>
                        </li>
                        <li className="nav-item position-relative">
                          {isConnected ? (
                            <>
                              <div className="subtext hover_connected nav-link">
                                Connected to {formattedAddress}
                              </div>
                              <span className="d-none show_address">{formattedAddress}</span>
                            </>
                          ) : (
                            <button className="nav-link" onClick={() => handleConnect()}>
                              Connect ERC Wallet
                            </button>
                          )}
                        </li>
                        <WalletMultiButton className="wallet-button" style={{ display: "flex", color: "white" }} />
                        <li className="nav-item">
                          <Link to="/add-sol-coins" className="nav-link">Create Solana Coin</Link>
                        </li>
                        <li className="nav-item">
                          {isConnected && (
                            <button className="nav-link" onClick={() => disconnect()}>
                              Disconnect
                            </button>
                          )}
                        </li>
                        {/* <WalletMultiButton /> */}
                        {showMenu && user && (
                          <div className="show_mobile_menu">
                            <div className="wallet__dropdown-top d-flex align-items-end" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                              <div className="wallet__dropdown-user mr-15">
                                <img src="/images/user2.png" alt="" />
                              </div>
                              <div className="wallet__dropdown-user-content">
                                <h4>{user?.first_name} {user?.last_name}</h4>
                              </div>
                              <img src="/images/DropDown.svg" alt="down" style={{ marginLeft: "20px" }} />
                            </div>
                            {showMobileMenu &&
                              <>
                                {isConnected && <div className="wallet__dropdown-id">
                                  <h5>Wallet:</h5>
                                  {/* <p>{formattedAddress}</p> */}
                                  {/* <div className="d-flex align-items-center gap-1 cur_point"> */}
                                  <p>{formattedAddress}</p>
                                  <div onClick={() => {
                                    copy(address)
                                    setCopied(true)
                                    setTimeout(() => {
                                      setCopied(false)
                                    }, [2000])
                                  }}> {copied ? <i className="fa-duotone fa-solid fa-check coppy-icon"></i> : <i className="fa-regular fa-copy coppy-icon"></i>} </div>
                                  {/* </div> */}
                                </div>}
                                <div className="wallet__dropdown-action">
                                  <ul>
                                    <li className="cur_point text-white" onClick={() => navigate("/profile")}>
                                      <i className="fa-regular fa-user"></i> Profile
                                    </li>
                                    <li onClick={handleLogOut} className="cur_point text-white">
                                      <i className="fa fa-arrow-right-from-bracket"></i> Logout
                                    </li>
                                  </ul>
                                </div>
                              </>}
                          </div>
                        )}
                        <div className="hide_mobile_menu header__user header__user-login" id="header__user-login">
                          <a>
                            <img src="/images/user2.png" alt="" />
                          </a>
                          <div className="wallet__dropdown">
                            <div className="wallet__dropdown-top d-flex align-items-end">
                              <div className="wallet__dropdown-user mr-15">
                                <img src="/images/user2.png" alt="" />
                              </div>
                              <div className="wallet__dropdown-user-content">
                                <h4>{user?.first_name} {user?.last_name}</h4>
                              </div>
                            </div>
                            {isConnected && <div className="wallet__dropdown-id">
                              <h5>Wallet:</h5>
                              <div className="d-flex align-items-center gap-1 cur_point">
                                <p>{formattedAddress}</p>
                                <div onClick={() => {
                                  copy(address)
                                  setCopied(true)
                                  setTimeout(() => {
                                    setCopied(false)
                                  }, [2000])
                                }}> {copied ? <i className="fa-duotone fa-solid fa-check coppy-icon"></i> : <i className="fa-regular fa-copy coppy-icon"></i>} </div>
                              </div>
                            </div>}
                            <div className="wallet__dropdown-action">
                              <ul>
                                <li className="cur_point text-white" onClick={() => navigate("/profile")}>
                                  <i className="fa-regular fa-user"></i> Profile
                                </li>
                                <li onClick={handleLogOut} className="cur_point text-white">
                                  <i className="fa fa-arrow-right-from-bracket"></i> Logout
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <CommonModal
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(!isOpen);
        }}
        className={"common-modal-title"}
        maxWidth={"300px"}
        title={"Connect your wallet"}
      >
        <ConnectWallet />
      </CommonModal>
    </>
  );
};

export default Header;
