import axios from "axios";
// import conf from "@/conf/conf";
import Cookies, { cookieKeys } from "./cookies";
import { logout } from "../modules/login/slice/loginSlice";
// import store from "@/store";
import store from "../store/index";

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });
    this.axios.interceptors.request.use(this._requestMiddleware);
    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get(cookieKeys?.TOKEN);

    if (token)
      req.headers.authorization = token.startsWith("Token")
        ? token
        : "Bearer " + token;
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.
    if (response?.data?.data?.token) {
      Cookies.set(cookieKeys?.TOKEN, response.data.data.token);
    }
    return response;
  };

  _responseErr = (error) => {
    //if error comes than handle here
    if (error?.response?.status === 401) {
      console.log("error ", error);

      Cookies?.clear();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosCoinex = new Axios(API_URL).axios;
export { axiosCoinex };
